.page-template-produkt-level-1 {
    .new-site-heading {
        color: white;
        background-color: #302f2f;
        padding: rem(36) 0;

        @media screen and (min-width: 768px) {
            padding: rem(42) 0;
        }

        @media screen and (min-width: 1200px) {
            padding: rem(60) 0;
        }
    }

    h2 {
        font-size: rem(22);
        font-weight: 300;
    }

    h1 {
        font-size: rem(50);
        font-weight: 500;
    }

    .go-back {
        font-size: 1.5rem;
        margin-top: 2rem;
        color: white;

        .chev-prev {
            transform: scaleY(-1);
            height: 1em;
            width: auto;
            position: relative;
            top: -2px;
        }
    }

    .subpages-wrap {
        background-color: #f1f1f1;
        padding: rem(36) 0;

        @media screen and (min-width: 768px) {
            padding: rem(75) 0;
        }

        @media screen and (min-width: 1200px) {
            padding: rem(90) 0;
        }

        .product-wrapper {
            max-width: none;

            .col {
                padding-right: 1rem;
                padding-left: 1rem;

                @media screen and (min-width:922px) {
                    padding-right: 3rem;
                    padding-left: 3rem;
                }

                @media screen and (min-width:1400px) {
                    padding-right: 5em;
                    padding-left: 5rem;
                }

                @media screen and (min-width:1560px) {
                    padding-right: 9rem;
                    padding-left: 9rem;
                }

                @media screen and (min-width:2000px) {
                    padding-right: 20rem;
                    padding-left: 20rem;
                }
            }
        }

        .subpages {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: rem(32);

            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(2, 1fr);
                gap: rem(32);
            }

            @media screen and (min-width: 1200px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media screen and (min-width: 1400px) {

            }

            @media screen and (min-width: 1560px) {

            }

            .item-page {
                color: #181717;
                background: white;
                display: flex;
                flex-direction: column;
                box-shadow: 0 0 15px 0 #8e8e8e;

                .bottom-bar {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;

                    //this property is bad for mobile 
                    @media screen and (min-width:1200px) {
                        padding: rem(20);
                    }

                    .title {
                        font-size: rem(22);
                        font-weight: 500;
                        text-align: center;
                        padding-bottom: 1rem;
                        border-bottom: 1px solid #d8d8d8;
                        padding-top: 1rem;

                        @media screen and (min-width: 922px) {
                            padding-top: 1.5rem;
                            font-weight: 700;
                        }

                        @media screen and (min-width: 1600px) {
                            font-size: rem(26);
                        }
                    }

                    .second-row {
                        padding-top: 1rem;
                        display: grid;
                        grid-template-columns: 1fr;
                        justify-content: space-between;
                        align-items: center;
                        font-size: rem(16);
                        margin-top: auto;
                        margin-bottom: auto;
                        padding: 1rem;

                        @media screen and (min-width: 768px) {
                            padding-bottom: unset;
                            padding: 1rem;
                        }

                        @media screen and (min-width: 992px) {
                            margin-top: 0px;
                        }

                        @media screen and (min-width: 1200px) {
                            grid-template-columns: 1fr;
                            gap: 2rem;
                            justify-items: flex-start;
                        }
                    }

                    .colors {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        grid-auto-flow: dense;
                        gap: rem(13);
                        margin-top: 1rem;
                        justify-items: center;
                        direction: rtl;

                        @media screen and (min-width:414px) {
                            grid-template-columns: repeat(5, 1fr);
                        }

                        @media screen and (min-width:768px) {
                            grid-template-columns: repeat(4, 1fr);
                        }

                        @media screen and (min-width:992px) {
                            grid-template-columns: repeat(5, 1fr);
                        }

                        @media screen and (min-width: 1200px) {
                            grid-template-columns: repeat(4, 1fr);
                            margin-top: 0;
                            transform: scaleX(-1);
                        }

                        @media screen and (min-width: 2400px) {
                            grid-template-columns: repeat(5, 1fr);
                        }

                        .color {
                            width: rem(64);
                            height: rem(64);
                            border: 1px solid #e4e4e4;
                            border-radius: 2px;

                            @media screen and (min-width:576px) {
                                width: 5rem;
                                height: 5rem;
                            }

                            @media screen and (min-width:768px) {
                                width: rem(64);
                                height: rem(64);
                            }

                            @media screen and (min-width: 1400px) {
                                width: 5rem;
                                height: 5rem;
                            }

                            @media screen and (min-width: 1700px) {
                                width: 6rem;
                                height: 6rem;
                            }

                            img {
                                border-radius: 2px;
                            }
                        }
                    }
                }
            }

            .thumb {
                width: 100%;
                aspect-ratio: 1.614420063;
            }

            img {
                width: 100%;
                height: 100%;
                aspect-ratio: 1.614420063;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}