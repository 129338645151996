@mixin tns-dots {
    .dot {
        width: 1rem;
        height: 1rem;
        border: 4px solid #ffffff;
        margin-right: 0.7em;
        border-radius: 17px;
        position: relative;
        cursor: pointer;
        box-shadow: 0px 0px 2px 2px green;

        &.tns-nav-active {
            background-color: $frontPageGreenDecoration;
        }
    }
}

body.home {
    font-family: $frontPageFont;
}

.tippy-box {
    background: white;
    border-radius: 9px;
    color: #181717;
    font-weight: 500;
    text-align: center;
    box-shadow: 0 0 rem(18) rgba(0, 0, 0, 0.18);

    &[data-placement^="top"]>.tippy-arrow::before {
        border-top-color: white;
    }

    &[data-placement^="bottom"]>.tippy-arrow::before {
        border-bottom-color: white;
    }

    &[data-placement^="left"]>.tippy-arrow::before {
        border-left-color: white;
    }

    &[data-placement^="right"]>.tippy-arrow::before {
        border-right-color: white;
    }

    img {
        width: 290px;
        height: 210px;
        object-fit: cover;

        &.no-image {
            display: none;
        }
    }

    .title {
        margin: 1rem;
        font-size: rem(18);
        font-weight: 600;
    }

    .tech-content {
        padding: 1.2rem;
        font-size: rem(16);
        text-align: start;

        .title {
            margin: 0 0 rem(28) 0;
        }
    }
}

.container-fluid.kostka {
    .offer-wrap {
        .item {
            text-transform: uppercase;
        }
    }

    h2 {
        font-size: 4.8em;
    }

    .green-circles {
        .circle {
            &:not(.last-child) {
                margin-right: 35px;
            }

            width: 170px;
            height: 170px;
            background-image: url(../../images/home/green-circle.webp);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        margin-top: 4em;
        margin-bottom: 3em;
    }

    p {
        font-size: 1.125em;
    }

    .big-circle {
        img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
        }

        padding: 20px;
        background: $greenGradient;
        border-radius: 50%;
        max-width: 605px;
        max-height: 605px;
        width: 100%;
        height: auto;
        // heigh¢’ t: 100%;
    }

    .small-circle {
        img {
            border-radius: 50%;
        }

        .chevron {
            position: absolute;
            left: 45%;
            font-weight: bold;
            font-size: 2em;
            transform: scaleY(1.4);
            font-family: "Open Sans", sans-serif;
            top: 33%;
            text-shadow: 0px 0px 15px rgb(0, 0, 0);
        }

        max-width: 150px;
        max-height: 150px;
        width: 100%;
        height: 100%;
        position: absolute;
        // top: calc(50% - 90px);
        top: 10vw;
        right: -50px;
        border-radius: 50%;
        background: $greenGradient;
        cursor: pointer;
        padding: 6px;
    }

    background-image: url(../../images/home/background-2.webp);
    background-size: cover;
    padding-top: 140px;
    padding-bottom: 200px;
    position: relative;
    color: white;
}

.container-fluid.offer {
    .offer-wrap {
        .item {
            &:hover {
                text-decoration: none;
                font-size: 1.7em;
            }

            @extend %fullSize;
            flex: 1;
            text-align: center;
            font-size: 1.5em;
            color: white;
            font-family: $montserrat;
            font-weight: $semi-bold;
            text-shadow: 0px 0px 57px rgba(0, 0, 0, 0.81);
            padding: 220px 15px 210px 15px;
            text-transform: uppercase;
            transition: 0.5s font-size;
        }

        .item.kostka {
            background-image: url(../../images/home/oferta-kostka.webp);
        }

        .item.plytki {
            background-image: url(../../images/home/oferta-plytki.webp);
        }

        .item.granit {
            background-image: url(../../images/home/oferta-granit.webp);
        }

        .item.nostalit {
            background-image: url(../../images/home/oferta-nostalit.webp);
        }

        .item.cegielka {
            background-image: url(../../images/home/oferta-cegielka.webp);
        }

        .item.pelna {
            background-color: #191a1b;
            @include aColor(white);

            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                top: -20px;
            }
        }
    }

    padding: 0;
}

.container-fluid.architektura {
    .row {
        div:nth-child(1) {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }

        div:nth-child(2) {
            a.green-button-chevron {
                .chevron {
                    margin-left: 2px;
                }

                display: flex;
            }

            h3 {
                @extend %h3;
            }

            @extend %flexCenterBothColumn;
            color: white;
        }

        div:nth-child(3) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
        }
    }

    padding: 250px 0;
    background-color: #222425;
}

.container-fluid.systemy {
    .green-gradient {
        .content {
            max-width: 500px;
        }

        .border-left {
            border-left: 3px solid white;
            padding-left: 35px;
            @extend %h3;
        }

        @extend %flexCenterBothColumn;
    }
}

.container-fluid.offer-2 {
    >.row> {
        div:nth-child(odd) {
            background-color: #ececec;
        }

        div:nth-child(even) {
            background-color: $lightGrey;
        }

        div {
            .border-left {
                font-weight: bold;
                font-family: $montserrat;
                font-size: 1.625em;
                line-height: 1.5;
                min-height: 1.5 * 1.625em;
                display: flex;
                align-items: center;
                color: $dark;
                padding-left: 35px;
                border-left: 2px solid $lightGreen !important;
            }

            .img-wrap {
                min-height: 350px;
                padding-right: 85px;
            }

            @include aColor($dark);
            padding-left: 80px;
            padding-top: 85px;
        }

        .alt {
            .border-left {
                div {
                    background-color: #222425;
                    padding: 0.5em 1em;
                }

                color: white;
                border-left-width: 10px;
                padding: 0;
            }

            .img-wrap {
                visibility: hidden;
            }
        }
    }

    .chevron-button {
        bottom: 0;
        right: 0;
        position: absolute;
    }
}

.products {
    --greenBg: #1b6b02;
    z-index: 20;
    position: relative;

    @media screen and (min-width: 768px) {
        height: var(--siteHeight);
    }

    .outer-grid {
        display: grid;
        grid-template-columns: 1fr;
        color: white;

        @media screen and (min-width: 768px) {
            overflow-y: hidden;
        }

        @media screen and (min-width: 1200px) {
            grid-template-columns: rem(300) auto;
        }

        @media screen and (min-width: 1600px) {
            grid-template-columns: rem(390) auto;
        }

        >div {
            @media screen and (min-width: 768px) {
                height: var(--siteHeight);
            }
        }

        .list-col {
            background-color: var(--greenBg);
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: rem(16);

            @media screen and (max-width: 1199.98px) {
                // display: flex;
                position: fixed;
                width: 50vw;
                height: 100%;
                left: -50vw;
                top: 0;
                z-index: 20;
                transition: left 0.3s ease-in-out;

                &.float-open {
                    left: 0;
                }
            }

            @media screen and (max-width: 767.98px) {
                width: 75vw;
                left: -75vw;
            }

            @media screen and (min-width: 1600px) {
                font-size: rem(19);
            }

            .close {
                position: absolute;
                right: 1rem;
                top: 0.75rem;
                font-size: 2rem;

                @media screen and (min-width: 1200px) {
                    display: none;
                }
            }

            .list {
                height: calc(var(--siteHeight) - (2 * 4rem));
                overflow-y: scroll;
                @include scrollbars(0.75rem, #ffffff, transparent);
            }

            .up,
            .down {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-grow: 1;
                cursor: pointer;
                -webkit-user-select: none;
                user-select: none;
                transition: opacity 0.3s;
            }

            .up {
                img {
                    transform: rotate(-90deg);
                }
            }

            .down {
                img {
                    transform: rotate(90deg);
                }
            }

            .item {
                padding: rem(20) 0 rem(20) rem(40);
                cursor: pointer;
                transition: background-color 0.3s ease-in-out;

                &:not(:last-child) {
                    border-bottom: 1px solid #519c43;
                }

                &.selected {
                    background-color: #519c43;
                }
            }
        }

        .inner-grid {
            display: none;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto auto;

            @media screen and (min-width: 768px) {
                grid-template-columns: 4rem 1fr 1fr;
            }

            @media screen and (min-width: 1200px) {
                grid-template-columns: 2fr 0.9fr;
                grid-template-rows: auto 1fr 2fr;
            }

            &.active {
                display: grid;
            }

            h1,
            h2 {
                margin-bottom: rem(24);

                @media screen and (min-width: 768px) {
                    margin-bottom: rem(32);
                }

                @media screen and (min-width: 1200px) {
                    margin-bottom: rem(40);
                }
            }

            .prod-info {
                display: flex;
                flex-direction: column;
                padding: 1.5rem 2rem;
                background-color: #1f1f1f;
                grid-row: 2;
                grid-column: 1;

                @media screen and (min-width: 768px) {
                    grid-row: 1;
                    grid-column: 2;
                    padding: 2rem 2.5rem;
                }

                @media screen and (min-width: 1200px) {
                    padding: 2.5rem;
                    grid-row: unset;
                    grid-column: unset;
                }

                @media screen and (min-width: 1600px) {
                    padding: 3rem 5rem;
                }
            }

            .prod-colors {
                background-color: #302f2f;
                padding: 2.5rem 2rem 5rem;
                grid-row: 4;

                @media screen and (min-width: 768px) {
                    grid-row: unset;
                    padding: 2rem 2.5rem;
                }

                @media screen and (min-width: 1200px) {
                    padding: 2.5rem;
                }

                @media screen and (min-width: 1600px) {
                    padding: 3rem;
                }
            }

            .prod-tech {
                display: flex;
                flex-direction: column;
                background-color: #ffffff;
                color: #181717;
                padding: 1.5rem 2rem;
                grid-row: 5;

                @media screen and (min-width: 768px) {
                    grid-row: unset;
                    padding: 2rem 2.5rem;
                }

                @media screen and (min-width: 1200px) {
                    padding: 2.5rem;
                }

                @media screen and (min-width: 1600px) {
                    padding: 3rem;
                }

                .tech-boxes {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 2rem;

                    @media screen and (min-width: 1200px) {
                        gap: 1rem;
                    }
                }
            }

            .prod-images {
                overflow: hidden;
                position: relative;
                grid-row: 3;

                @media screen and (min-width: 768px) {
                    grid-column: 1 / span 3;
                    grid-row: 3;
                }

                @media screen and (min-width: 1200px) {
                    grid-column: unset;
                    grid-row: span 2;
                }

                .images-nav {
                    display: flex;
                    position: absolute;
                    justify-content: center;
                    bottom: rem(36);
                    width: 100%;
                    left: 0;

                    @include tns-dots();

                    .items {
                        display: flex;
                    }
                }

                .tns-outer {
                    height: 100%;

                    * {
                        height: 100%;
                    }
                }

                img {
                    width: 100%;
                    // max-height: 70vh;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .prod-more {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--greenBg);
                font-size: rem(18);
                font-weight: 500;
                padding: 2rem;
                grid-row: 6;
                grid-column: 1;

                @media screen and (min-width: 768px) {
                    grid-row: 2;
                    grid-column: 2;
                }

                @media screen and (min-width: 1200px) {
                    grid-row: unset;
                    grid-column: unset;
                    padding: 2rem;
                }

                @media screen and (min-width: 1600px) {
                    font-size: rem(22);
                }
            }

            .prod-list-toggler {
                grid-row: 1;
                grid-column: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.2rem;
                font-weight: 560;
                background: #009c3c;
                -webkit-user-select: none;
                user-select: none;
                cursor: pointer;
                padding: 1rem;
                white-space: nowrap;

                @media screen and (min-width: 768px) {
                    writing-mode: vertical-lr;
                    text-orientation: mixed;
                    transform: rotate(180deg);
                    font-size: 1.5rem;
                    padding: 0;
                }

                @media screen and (min-width: 768px) {
                    grid-row: 1 / span 2;
                    grid-column: 1;
                }
            }
        }
    }
}

.termo-block {
    display: flex;
    align-items: stretch;

    @media screen and (min-width: 768px) {
        height: var(--siteHeight);

        >div {
            height: var(--siteHeight);

            >div {
                height: var(--siteHeight);

                >div {
                    height: var(--siteHeight);
                }
            }
        }
    }

    .termoblock-desc {
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;

        .wrap {
            max-width: 90vw;
            padding: 1.5rem 0;

            @media screen and (min-width: 768px) {
                max-width: min(45.3125rem, 40vw);
                padding: 0;
            }

            img {
                max-height: 30vh;
                width: auto;
                height: auto;

                @media screen and (min-width: 1600px) {
                    max-height: unset;
                }
            }
        }
    }
}

.all-products {
    background-color: #302f2f;
    padding: rem(32) 0;
    color: white;

    @media screen and (min-width: 768px) {
        padding: rem(95) 0;
    }

    .prods {
        margin-top: rem(32);
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: rem(31);

        @media screen and (min-width: 768px) {
            margin-top: rem(95);
            grid-template-columns: repeat(2, 1fr);
            gap: rem(62);
        }

        @media screen and (min-width: 1200px) {
            grid-template-columns: repeat(3, 1fr);
        }

        >.prod {
            display: block;
        }

        .title {
            background-color: white;
            color: #181717;
            font-size: rem(20);
            padding: rem(24) 0;
            text-align: center;
            font-weight: 500;
            position: relative;

            @media screen and (min-width: 768px) {
                font-size: rem(22);
                padding: rem(32) 0;
            }

            @media screen and (min-width: 1200px) {
                padding: rem(40) 0;
            }

            @media screen and (min-width: 1600px) {
                font-size: rem(28);
            }

            &::before {
                display: block;
                content: "";
                position: absolute;
                width: 77px;
                top: -4.5px;
                left: calc(50% - (77px / 2));
                height: 9px;
                border-radius: 4.5px;
                background-color: $frontPageGreenDecoration;
            }
        }

        img {
            aspect-ratio: 1.624605678;
            width: 100%;
            object-fit: cover;
        }
    }
}

.container-fluid.promo {
    >.row:nth-child(1) {
        >div:nth-child(1) {
            background-color: #070808;
            padding-bottom: $mb;
            padding-top: $mt;
            padding-right: $mr;
        }

        >div:nth-child(2) {
            background-color: #222425;
            padding-bottom: $mb;
            padding-top: $mt;
            padding-left: $ml;
        }
    }

    >.row:nth-child(2) {
        >div:nth-child(1) {
            background-color: #222425;
            padding-top: $mt;
            padding-bottom: $mb;
            padding-right: $ml;
        }

        >div:nth-child(2) {
            background-color: #070808;
            padding-top: $mt;
            padding-bottom: $mb;
            padding-left: $mr;
        }
    }

    .row {
        .content {
            text-align: center;
            max-width: 400px;
        }
    }

    .image-wrap {
        img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
        }

        .nav-button {
            &.next {
                right: 2px;
                background-color: #449337;
            }

            &.prev {
                transform: rotate(180deg);
                left: 2px;
            }

            position: absolute;
            background-image: url(../../images/common/chevron.png);
            background-position: center;
            background-repeat: no-repeat;
            background-color: $lightGreen;
            width: 32px;
            height: 32px;
            top: 45%;
        }

        max-width: 400px;
        max-height: 400px;
        width: 100%;
        height: auto;
        padding: 8px;
        background: $greenGradient;
        border-radius: 50%;
    }

    .green-circle {
        .content {
            &.gray {
                background-color: #222425;
            }

            border-radius: 50%;
            background-color: #070808;
            width: 100%;
            height: 100%;
            @extend %flexCenterBothColumn;
        }

        margin: 0 auto 50px auto;
        // margin-bottom: 95px;
        border-radius: 50%;
        width: 190px;
        height: 190px;
        padding: 5px;
        background: $greenGradient;
    }

    color: white;
    position: relative;
}

body.home {
    video {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
        overflow: hidden;
    }

    background-position: top;
    background-size: auto;
}

$color_1: white;
$color_2: #070808;
$color_3: #2c6c20;
$background-color_1: #009c34;

.home-banners-row {
	--sheight: 100vh - var(--headerMaxHeight);
	.tns-outer {
		width: 100%;
		height: 100%;
	}
	.tns-item {
		display: flex !important;
		height: 100%;
		>div.row {
			width: 100%;
		}
	}
	.main-gallery {
		position: relative;
	}
	.tns-inner {
		height: 100%;
		>div {
			height: 100%;
			>a {
				height: 100%;
				>div {
					height: 100%;
				}
			}
		}
	}
	.home-banner {
		color: $color_1;
		.text-part {
			display: flex;
			background: white;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			h1 {
				font-weight: 300;
				font-size: 1.5rem;
			}
			h2 {
				font-weight: 300;
			}
			.content {
				display: flex;
				flex-direction: column;
				max-width: 90vw;
				column-gap: 2rem;
				align-items: center;
				text-align: center;
				font-weight: 300;
				font-size: 1.5rem;
				line-height: 1;
				p {
					grid-row: 2;
					grid-column: 1;
				}
				.btn {
					grid-row: 2;
					grid-column: 2;
				}
			}
			b {
				font-weight: 600;
			}
			strong {
				font-weight: 600;
			}
			.hero-catalogue-wrap {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				row-gap: 2rem;
				img {
					height: 100%;
					max-height: 25vh;
					width: auto;
				}
				.btn-catalog {
					margin: auto;
					margin-top: 70%;
				}
			}
		}
		.home-banner-img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: left;
		}
	}
	.home-banners-dots {
		display: flex;
		flex-direction: row;
		position: absolute;
		right: 0.05rem;
		bottom: 1rem;
		z-index: 10;
		.dot {
			width: 1rem;
			height: 1rem;
			border: 4px solid #ffffff;
			margin-right: 0.7em;
			border-radius: 17px;
			position: relative;
			cursor: pointer;
			box-shadow: 0px 0px 2px 2px green;
		}
		.dot.tns-nav-active {
			background-color: $background-color_1;
		}
	}
	.home-banner-next {
		position: absolute;
		top: calc(50% - 24px);
		cursor: pointer;
		right: 0;
		transform: scale(-1);
	}
	.home-banner-prev {
		position: absolute;
		top: calc(50% - 24px);
		cursor: pointer;
		left: 0;
	}
	.f-wrap {
		display: flex;
		flex-direction: column;
		padding: 0 !important;
		.new-prod-advert {
			h1 {
				color: $color_2 !important;
				padding-top: 0rem;
				font-weight: 700;
			}
			p {
				color: $color_2 !important;
				font-weight: 400;
			}
			.hero-catalogue-wrap {
				display: block;
				padding-top: 1rem;
			}
			display: grid;
			grid-template-columns: 1fr 1fr;
			.promo-img {
				img {
					object-fit: cover;
					height: -webkit-fill-available;
				}
			}
			.promo-desc-wrapper {
				display: grid;
				.promo-desc {
					padding: 1rem 1rem 0 1rem;
					h2 {
						font-weight: bold;
						color: $color_3;
						text-transform: uppercase;
						font-size: 1rem;
					}
				}
			}
		}
		.text-and-btn {
			h1 {
				color: $color_2 !important;
				padding-top: 0rem;
				font-weight: 700;
			}
			p {
				color: $color_2 !important;
				font-weight: 400;
			}
			.hero-catalogue-wrap {
				display: block;
				padding-top: 1rem;
			}
		}
		.t-text {
			.catalog-section-wrapper {
				display: grid;
				grid-template-columns: 1fr 1fr;
			}
			padding: 1rem;
			background-position-y: bottom;
			background-repeat: no-repeat;
			.btn-img-wrapper {
				max-width: 60vw;
				padding-bottom: 1rem;
			}
		}
		.borehole-form {
			padding: 1rem;
			background-position-y: bottom;
			background-repeat: no-repeat;
			.btn-img-wrapper {
				max-width: 60vw;
				padding-bottom: 1rem;
			}
		}
	}
}
@media screen and (min-width: 1200px) {


	.home-banners-row {
		height: calc(100vh - var(--headerMaxHeight));
		.tns-inner {
			>div {
				>a {
					>div {
						>div {
							height: 100%;
						}
					}
				}
			}
		}
		.home-banner {
			.text-part {
				.content {
					display: flex;
					flex-direction: column;
					justify-content: center;
					max-width: unset;
					font-size: 2.5rem;
				}
			}
		}
		.home-banners-dots {
			bottom: 2.75rem;
		}

        --sHeight: 100vh - var(--headerMaxHeight);

		.f-wrap {
			padding: 1rem;
			display: grid;
			height: calc(var(--sHeight));
			.new-prod-advert {
				h1 {
					font-size: 2rem;
				}
				p {
					font-size: 0.8rem;
				}
				grid-template-rows: 40vh;
				.promo-img {
					img {
						width: 100%;
					}
				}
				.promo-desc-wrapper {
					.promo-desc {
						padding: 1rem 1rem;
					}
				}
			}
			.text-and-btn {
				h1 {
					font-size: 2rem;
				}
				p {
					font-size: 0.8rem;
				}
			}
		}
	}
}
@media screen and (min-width: 768px) {
	.home-banners-row {
		.home-banner {
			.text-part {
				h1 {
					font-size: 2.875rem;
				}
				.hero-catalogue-wrap {
					flex-direction: row;
				}
			}
		}
		.home-banners-dots {
			bottom: 1.375rem;
		}
		.f-wrap {
			.new-prod-advert {
				.promo-desc-wrapper {
					.promo-desc {
						padding: 3rem 1rem 0 1rem;
					}
				}
			}
		}
	}
}
@media screen and (min-width: 1600px) {
	.home-banners-row {
		.home-banner {
			.text-part {
				h1 {
					font-size: 4.6875rem;
				}
			}
		}
	}
}
@media screen and (max-width: 1560px) {
	.home-banners-row {
		.f-wrap {
			.new-btn-filled {
				padding: 0.6rem 0.6rem;
				font-size: 1rem;
			}
		}
	}
}
@media screen and (min-width: 1560px) {
	.home-banners-row {
		.f-wrap {
			.new-prod-advert {
				h1 {
					font-size: 3rem;
				}
				p {
					font-size: 1.0625rem;
				}
				.promo-desc-wrapper {
					.promo-desc {
						h2 {
							font-size: 1.6875rem;
						}
					}
				}
			}
			.text-and-btn {
				h1 {
					font-size: 3rem;
				}
				p {
					font-size: 1.0625rem;
				}
			}
			.t-text {
				padding: 2rem;
				background-position-y: 0px;
			}
			.borehole-form {
				padding: 2rem;
				background-position-y: 0px;
			}
		}
	}
}
@media screen and (min-width: 1880px) {
	.home-banners-row {
		.f-wrap {
			.new-prod-advert {
				.hero-catalogue-wrap {
					padding-top: 0px;
				}
			}
			.text-and-btn {
				.hero-catalogue-wrap {
					padding-top: 0px;
				}
			}
			.t-text {
				.btn-img-wrapper {
					max-width: unset;
				}
			}
			.borehole-form {
				.btn-img-wrapper {
					max-width: unset;
				}
			}
		}
	}
}
@media screen and (min-width: 2400px) {
	.home-banners-row {
		.f-wrap {
			.new-prod-advert {
				.promo-desc-wrapper {
					.promo-desc {
						padding: 2rem;
					}
				}
			}
			.t-text {
				.catalog-section-wrapper {
					padding-top: 7vh;
				}
			}
		}
	}
}
@media screen and (min-width: 2000px) {
	.home-banners-row {
		.f-wrap {
			.t-text {
				padding: 2rem;
				background-position-y: bottom;
			}
			.borehole-form {
				padding: 2rem;
				background-position-y: bottom;
			}
		}
	}
}


.text-and-btn {
    font-weight: 500;
}

a,
a:hover,
a:visited {
    color: rgb(255, 255, 255);
}


@media screen and (min-width: 992px) {
    html:not(.webp) {
        .container-fluid.kostka {
            background-image: url(../../images/home/background-2.png);
        }
    }
}

html:not(.webp) {
    .container-fluid.kostka {
        .green-circles {
            .circle {
                background-image: url(../../images/home/green-circle.png);
            }
        }
    }

    .container-fluid.offer {
        .offer-wrap {
            .item.kostka {
                background-image: url(../../images/home/oferta-kostka.jpg);
            }

            .item.plytki {
                background-image: url(../../images/home/oferta-plytki.jpg);
            }

            .item.granit {
                background-image: url(../../images/home/oferta-granit.jpg);
            }

            .item.nostalit {
                background-image: url(../../images/home/oferta-nostalit.jpg);
            }

            .item.cegielka {
                background-image: url(../../images/home/oferta-cegielka.jpg);
            }
        }
    }
}