.colors-wrap {
	display: grid;
	grid-template-columns: 1fr;
	column-gap: 1em;
	row-gap: 1em;
	width: 100%;
	height: fit-content;
	margin-top: auto;
}

@media screen and (min-width: 576px) {
	.colors-wrap {
		grid-template-columns: 1fr 1fr;
	}
}
@media screen and (min-width: 992px) {
	.colors-wrap {
		grid-template-columns: 1fr;
	}
}


.colors-column {
	.color {
		position: relative;
	}
	.name {
		margin-left: 15px;
		border-left: 2px solid $lightGreen;
		padding-left: 15px;
		color: #252728;
	}
	flex: 1;
}