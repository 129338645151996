.page-template-poradnik {
	.behind-logo {
		background-color: $lightGrey;
		background-image: url(../../images/poradnik/bg.webp);
		background-size: contain;
		background-repeat: no-repeat;
	}
	.decor-hr {
		max-width: 25px;
		margin: 0 auto 0.2em auto;
	}
}

html:not(.webp) {
	.page-template-poradnik {
		.behind-logo {
			background-image: url(../../images/poradnik/bg.jpg);
		}
	}
}