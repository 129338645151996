
.page-template-kontakt {
	.bg-1 {
		background-image: url(../../images/kontakt/bg-1.webp);
		@extend %fullSize;
		min-height: 100vh;
	}
	.map {
		background-image: url(../../images/kontakt/mapa.webp);
		@extend %fullSize;
		min-height: 100vh;
	}
	.item {
		h4 {
			font-weight: bold;
			font-size: 1.25em;
			text-transform: uppercase;
		}
	}
	#content {
		font-size: .9em;
		.container-fluid > .row > {
			div {
				padding: 50px 20px;
			}
		}
		form {
			input:not([type="submit"]):not([type="checkbox"]),
			textarea {
				color: $dark;
				padding: 10px;
				background-color: white;
				margin-bottom: 15px;
				border: 0;
				width: 100%;
				@include placeholderColor($dark);
			}
			input[type="submit"] {
				padding: 15px 20px;
				color: white;
				background-color: $lightGreen;
				font-size: 1.125em;
				font-weight: $medium;
				font-family: $montserrat;
				align-self: flex-end;
				float: right;
				position: relative;
			}
			textarea {
			}
		}
	}
	.clause {
		color: gray;
		font-size: 0.9em;
	}
	.move-to-top {
		border-bottom: 10px solid $lightGreen;
	}
	table {
		overflow-x: scroll;
	}
}

html:not(.webp) {
	.page-template-kontakt {
		.bg-1 {
			background-image: url(../../images/kontakt/bg-1.jpg);
		}
		.map {
			background-image: url(../../images/kontakt/mapa.jpg);
		}
	}
}
