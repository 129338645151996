.page-template-preparaty {
    .behind-logo {
        // background-image: url(../../images/preparaty/bg.webp);
        background-size: auto;
        background-repeat: no-repeat;
    }
}

html:not(.webp) {
    .page-template-preparaty {
        .behind-logo {
            // background-image: url(../../images/preparaty/bg.jpg);
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}
