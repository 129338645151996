$mobile-bottom-nav-header-padding: 1rem; 
$mobile-bottom-nav-header-size: 1rem; 
$mobile-bottom-nav-height: 90vh;
$border-radius: 10px;

.mobile-product-nav {
	&.open {
        .mobile-product-nav--content {
            display: grid;
        }
        .mobile-product-nav--header {
            .icon {
                display: none;
            }
        }
	}
	.mobile-product-nav--header {
		font-size: $mobile-bottom-nav-header-size;
		padding-top: $mobile-bottom-nav-header-padding;
		padding-bottom: $mobile-bottom-nav-header-padding;
		text-align: left;
        line-height: 1;
        box-shadow: 0 0 20px black;
        font-size: .9rem;
        background-color: #1d4716;
        .icon {
            display: inline-block;
        }
        .crumb {
            font-size: .8rem;
        }
        .action {
            font-size: 800;
            opacity: 0;
        }
    }
    .mobile-product-nav--content {
        display: none;
        row-gap: 1rem;
        column-gap: 1rem;
        grid-template-columns: 1fr;
        // max-height: calc(#{$mobile-bottom-nav-height} - (#{$mobile-bottom-nav-header-size} + 2 * #{$mobile-bottom-nav-header-padding}));
        overflow-y: auto;
    }

    .mobile-product-nav--content.grid {
        .mobile-product-nav--item {
            flex-direction: column !important;
            position: relative;
            box-shadow: 0 0 10px #0e3810;
            font-family: "Open Sans", Helvetica, sans-serif;
            .image {
                img {
                    width: 100%;
                    max-width: unset;
                    object-fit: cover;
                    border-radius: $border-radius;
                }
            }
            .text {
                flex-grow: 1;
                padding: .5rem;
                text-align: center;
                justify-content: center;
                margin-left: 0;
                text-align: center;
                position: absolute;
                bottom: 0;
                width: 100%;
                border-radius: 0 0 $border-radius $border-radius;
                background-color: #1d6332d6;
                h5 {
                    font-size: 1rem;
                }
            }
        }
        grid-template-columns: 1fr 1fr;
    }
    img {
        max-width: 100px;
    }
    .mobile-product-nav--item {
        text-align: center;
        color: white;
        cursor: pointer;
        margin-bottom: 1rem;
        border-radius: $border-radius;
        background: rgba(0,0,0,0.25);
        .text {
            display: flex;
            align-items: center;
            margin-left: 1rem;
            text-align: left;
        }
        &:hover,
        &:visited {
            color: white;
            text-decoration: none;
        }
    }
	color: white;
	position: relative;
	// height: calc(#{$mobile-bottom-nav-header-size} + 2 * #{$mobile-bottom-nav-header-padding});
	background-color: $green;
	transition: .3s height;
}

@media screen and (min-width: 576px) {
    .mobile-product-nav {
        .mobile-product-nav--content {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}