@media screen and (max-width: 991.98px) {
    .behind-logo {
        img {
            width: 100%;
            display: none;
        }
        position: absolute;
        opacity: 0;
        top: initial;
    }
    .page-template-krawezniki {
        .behind-logo {
            & + div {
                padding-top: 1em;
            }
            opacity: 0;
        }
    }
    .mobile-move-to-top {
        position: relative;
        // margin-top: -60px;
    }
    header {
        .nav-container {
            .dark-nav {
                font-size: 0.9em;
                .menu-row {
                    .header-contact {
                        padding-top: 0.5em;
                        padding-bottom: 0.5em;
                        align-content: center;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
                ul {
                    li:hover {
                        border-top: 0;
                        margin-top: 5px;
                    }
                    justify-content: space-between;
                }
                .search-row {
                    display: none;
                }
            }
            .nav-logo {
                padding: 0.2em 0.75em 0.75em 0;
                
                @media screen and (min-width:1200px) {
                    padding: 0.75em 0.75em 0.75em 0;
                }
            }
        }
    }
    .header {
        h1 {
            font-size: 2.5em;
        }
    }
    .page-template-main {
        .container-fluid.mobile-move-to-top {
            // background-image: url(../images/home/background-1.webp);
            background-color: #302f2f;
        }
    }

    .container-fluid.kostka {
        .slider_1 {
            .small-circle {
                position: relative;
                top: 0;
                right: 50px;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2em;
        }
        background: $greenGradient;
        h2 {
            font-size: 2.5em;
        }
        @include mobilePadding();
    }

    .container-fluid.architektura {
        .col-sm-12 {
            padding-left: 15px;
            padding-right: 15px;
        }
        @include mobilePadding();
    }
    .container-fluid.promo {
        .image-wrap {
            max-width: 33vw;
        }
    }
    .page-template-about-us {
        .behind-logo {
            display: none;
        }
        .bg-true-white {
            padding-top: 2em;
        }
        .signature-wrap {
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
    }
    footer {
        .items-wrap {
            flex-wrap: wrap;
        }
    }
    html:not(.webp) {
        .page-template-main {
            .container-fluid.mobile-move-to-top {
                background-image: url(../images/home/background-1.jpg);
            }
        }
    }
    .container-fluid.offer {
        .offer-wrap {
            flex-wrap: wrap;
        }
    }

    .page-template-ogordzenia {
        .fences {
            .fence-content {
                > *:not(:first-child) {
                    margin-top: 1em;
                }
                flex-wrap: wrap;
                max-width: 100%;
                overflow-x: scroll;
                flex-direction: column;
                align-items: center;
            }
        }
    }
    .page-template-stropy {
        .grey-top:last-child {
            .d-flex:last-child {
                .item:last-child {
                    margin-left: 0;
                }
                flex-wrap: wrap;
                justify-content: flex-start;
            }
        }
    }
    .site-logo {
        img {
            max-height: 105px;
        }
    }
    .page-template-stropy {
        #content .d-flex .item:last-child {
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 575.98px) {
    header {
        .nav-logo {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .header-contact {
        .item {
            flex-direction: row;
            align-items: center;
        }
    }
    .header {
        h1 {
            font-size: 2.3em;
        }
    }
    .container-fluid.kostka {
        .green-circles {
            margin-top: 2rem;
            margin-bottom: 1.5rem;
        }
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .container-fluid.katalog {
        .text {
            .border-left {
                font-size: 1.8em;
                margin-top: 1em;
            }
            margin-bottom: 2rem;
        }
    }
    .container-fluid.systemy {
        .green-gradient {
            .border-left {
                padding-left: 0.2em;
            }
        }
    }
    .container-fluid.systemy {
        .green-gradient {
            padding: 2em !important;
        }
    }
    .container-fluid.architektura {
        padding: 0;
    }
    .container-fluid.architektura {
        .colors {
            display: none;
        }
        .text-center {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }
    .container-fluid.offer-2 {
        > .row {
            > div {
                padding-left: 2em;
                padding-top: 2em;
            }
        }
    }
    .container-fluid.promo {
        > .row:nth-child(1) > div:nth-child(2) {
            padding: 2em;
        }
        > .row:nth-child(1) > div:nth-child(1) {
            padding: 2em;
        }
        > .row:nth-child(2) > div:nth-child(1) {
            padding: 2em;
        }

        > .row:nth-child(2) > div:nth-child(2) {
            padding: 2em;
        }
        .image-wrap {
            display: none;
        }
    }
    footer .row:not(.green) {
        .item {
            margin-top: 25px;
        }
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    footer .row.green {
        padding-bottom: 4em;
    }
}

@media screen and (max-width: 767.98px) {
    header {
        .menu-header-container {
            width: 100vw !important;
        }
    }

    .dark-nav {
        ul {
            li {
                &.current-menu-item,
                &:hover {
                    border-top: 0;
                }
            }
        }
    }
    .site-logo img {
        max-height: 40px;
    }
    .slider_2 {
        display: none;
    }
    footer {
        .row.green {
            padding-bottom: 60px;
        }
    }
    #modalDialog {
        .content {
            .main-image-wrap {
                height: 64vh;
                img {
                    max-height: 64vh;
                    margin-top: 1em;
                    margin-bottom: 1em;
                }
            }
            .thumbnails {
                .thumbs-wrap {
                    width: 128px;
                    height: 128px;
                }
                justify-content: space-around !important;
            }
            .actual-content {
                flex-direction: column-reverse;
            }
            height: 90%;
        }
    }
    .page-template-stropy {
        .rows-wrap > .row {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
    }
}

@media screen and (min-width: 768px) {
    .nav-logo .search-toggle {
        display: none;
    }

    .page-template-stropy {
        .rows-wrap {
            > .row:first-child {
                padding-top: 30px;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .container-fluid.architektura {
        .row {
            div:nth-child(2) {
                h3 {
                    font-size: 1.8em;
                }
            }
        }
    }
    .container-fluid.katalog {
        .text {
            .border-left {
                font-size: 2.3em;
            }
        }
    }
    header .nav-container .dark-nav .menu-row .header-contact {
        padding: 0;
        height: fit-content;
    }
    .site-logo img {
        max-height: 60px;
    }
    .header-contact {
        width: 220px;
        padding-left: 0px !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199.98px) {
    .dark-nav {
        .menu-row {
            > div:nth-child(1) {
                padding-top: 1em;
                padding-bottom: 0.5em;
            }
            > div:nth-child(2) {
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 1199.98px) {
    header {
        .nav-container {
            .dark-nav {
                ul {
                    li {
                        a {
                            width: 100%;
                            padding: 1rem;
                            font-size: 1em;
                        }
                    }
                }
            }
        }
        .menu-header-container {
            display: block !important;
            position: fixed;
            top: 0;
            left: -100vw;
            width: 50vw;
            height: 100%;
            // display: none;
            transition: 0.3s all;
            background-color: #252728;
            overflow-y: scroll;
            z-index: 1000;

            &.open {
                left: 0;
            }

            picture {
                margin: 1em;
            }

            ul {
                flex-direction: column;
            }
        }
        #menu-item-406 {
            display: flex;
            flex-direction: column;
            width: auto;

            #offerSubmenu {
                .container {
                    li {
                        // font-size: 1.2em;
                        margin: 1.8em 0 1.8em 0;
                    }
                    max-width: 100%;
                }
                position: relative;
                top: 0;
                right: 0;
            }
        }
    }
    #menu-header li.search-toggle {
        display: none;
    }

    .left-side-video {
        display: none;
    }
    .gallery {
        .item {
            max-width: 100px;
        }
    }
    // .behind-logo {
    //     & + div {
    //         margin-top: 1em;
    //     }
    // }
    .container-fluid.systemy {
        .green-gradient {
            padding: 5em;
        }
    }
    body.home {
        video {
            display: none;
        }
    }
}

@media screen and (min-width: 992px) {
    .site-logo img {
        max-width: 400px;
        width: 100%;
    }
    header {
        .nav-container {
            padding-bottom: 0em;
        }
    }
    .container-fluid.kostka {
        margin-top: -3%;
    }
    .home-banners-dots {
        display: flex;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
    .container-fluid.kostka {
        .small-circle {
            position: absolute;
            right: 20%;
            top: 35%;
        }
    }
    body.home {
        #search-form {
            margin-top: 1em;
            margin-right: 1em;
        }
    }
}

@media screen and (min-width: 1200px) {
    .scroll-wrap {
        overflow-x: visible;
    }

    #nav-toggle {
        display: none;
    }

    #menu-header {
        .mobile-top {
            display: none;
        }
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px), screen and (max-width: 575.98px) {
    .page-template-kontakt {
        .scroll-wrap {
            table {
                tr {
                    td {
                        &.custom-center {
                            text-align: left;
                        }
                        width: 100%;
                    }
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 0.7em;
                }
            }
        }
        #content {
            .container-fluid {
                > .row {
                    > div {
                        padding-top: 65px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    body.home {
        background-size: contain;
    }
}
