.page-template-downloads {
    @media screen and (min-width: 992px) {
        .page-template-downloads {
            .promo-dwn-baner {
                .promo-desc-dwn-page-wrapper {
                    h1 {
                        font-size: 2rem;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 992px) and (max-width: 1920px) {
        .page-template-downloads {
            .promo-dwn-baner {
                background-position-x: -100px;
                .promo-desc-dwn-page-wrapper {
                    padding: 0px;
                }
            }
        }
    }

    .promo-dwn-baner {
		min-height: 600px;
		.promo-desc-dwn-page-wrapper {
			padding: 0 0 0 6rem;
			h1 {
				font-weight: 700;
			}
			.promo-desc-dwn-page {
				padding: 1.5rem;
			}
			.btn-dwn-page {
				display: flex;
				flex-flow: row-reverse;
				padding-top: 4rem;
			}
		}
	}

    .downloads-wrap {
        a {
            display: flex;
        }
        @include aColor($dark);
    }
    #content {
        padding-bottom: 2em;
        background-color: white;
    }
    .behind-logo {
        background-color: $lightGrey;
    }

    .dwn-catalog {
        background-image: url(../../images/downloads/katalog-bg.webp);
        background-size: auto;
        background-repeat: no-repeat;
    }
    .green-border {
        padding-left: 0.3em;
        border-left: 2px solid green;
        font-size: 2em;
        font-weight: bold;
        line-height: 1.1;
    }
    #search-form {
        input#fileSearch {
            @media screen and (max-width: 1199.98px) {
                width: 100%;
            }
        }
    }

    
}

html:not(.webp) {
    .page-template-downloads {
        .behind-logo {
            background-image: url(../../images/downloads/katalog-bg.jpg);
        }
    }
}
