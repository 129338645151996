@import "variables";
@import "mixins";
@import "~bootstrap/scss/bootstrap";
@import "functions.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    // 50% {
    // 	transform: rotate(360deg)
    // }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

@keyframes bounce-horizontally {

    0%,
    100% {
        right: 0;
        letter-spacing: 5px;
    }

    50% {
        right: 1em;
        letter-spacing: 10px;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        display: none;
    }
}

:root {
    font-size: 16px;

    @media screen and (min-width: 1200px) {
        font-size: 15px;
    }

    @media screen and (min-width: 1600px) {
        font-size: 16px;
    }
}

.anim-spin {
    animation: 1s spin ease-in-out;
    animation-iteration-count: infinite;
}

.anim-bounce {
    animation: 1s bounce ease-in-out;
    animation-iteration-count: infinite;
}

#loader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 2000;
    background-size: cover;
    background-position: center;
}

body {
    @include aColor($lightGreen);
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    color: $dark;
    background-color: #f8f8f8;
    margin-bottom: 0 !important;
    --headerMaxHeight: 67px;
    // --siteHeight: calc(100vh - var(--headerMaxHeight));
    --siteHeight: 100vh;

    // @media screen and (min-width: 1200px) {
    //     --headerMaxHeight: 72px;
    // }

    @media screen and (min-width: 1600px) {
        --headerMaxHeight: 72px;
    }

    @media screen and (min-width: 1850px) {
        --headerMaxHeight: 90px;
    }

    @media screen and (min-width: 992px) {
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: $green;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0);
        }

        scrollbar-face-color: $green;
        scrollbar-track-color: rgba(0, 0, 0, 0);
    }

    .katalog {
        background-color: #eee;
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        padding-top: rem(24);
        font-size: rem(18);

        @media screen and (min-width: 768px) {
            background-image: url(../images/new_homepage/footer-bg.webp);
        }

        @media screen and (min-width: 1200px) {
            background-size: 50% auto;
        }

        .katalog-image {
            // &:hover {
            // 	transform: matrix(1.2, 0.3, -0.4, 1.2, 0, 25);
            // 	margin-right: 50px;
            // }
            max-width: 450px;
            width: 100%;
            right: 0;
            bottom: 15%;
            transition: 1s all;
            cursor: pointer;
        }

        .text {
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 1.5rem;
            padding-left: 1rem;
        }

        a.green {
            font-family: $montserrat;
            font-weight: $medium;
            font-size: 1.2em;
            color: $lightGreen;
        }

        h2 {
            font-size: rem(44);
            position: relative;

            &::first-line {
                font-weight: bold;
            }
        }
    }
}

.site-content {
    max-width: 3000px;
    margin: 0 auto;
}

body,
html {
    overflow-x: hidden;
}

body.search {
    article {
        max-width: 300px;
    }

    .entry-title {
        text-align: center;
        @include aColor($dark);
    }
}

body.no-scroll {
    max-height: 100vh;
    overflow: hidden;
}

.flex-100 {
    flex: 0 0 100% !important;
    border-bottom: 1px solid #e2e2e2;
}

a.green-button {
    padding: 12px 20px 12px 25px;
    background-color: $green;
    font-family: $montserrat;
    font-weight: 500;
}

a.green-button {
    padding: 12px 20px 12px 25px;
    background-color: $green;
    font-family: $montserrat;
    font-weight: 500;
    margin-top: 2em;
}

a.green-button-chevron {
    .chevron {
        display: inline-flex;
        height: 100%;
        width: 41px;
        margin-left: -1px;
        align-self: stretch;
        padding: 12px 10px 12px 15px;
        background-color: $lightGreen;
    }

    .text {
        background-color: $lightGreen;
        padding: 12px 20px 12px 25px;
        font-weight: 500;
        font-family: $montserrat;
        display: inline-flex;
    }

    margin-top: 2em;
}

a,
a:visited,
a:hover {
    &.green-button {
        color: white;
        text-decoration: none;
    }

    &.green-button-chevron {
        color: white;
        text-decoration: none;
    }
}

.hr {
    &.white-hr {
        background-color: white;
    }

    &.green-hr {
        background-color: $lightGreen;
    }

    width: 80px;
    height: 2px;
    margin: 1.6em auto;
}

.chevron-button {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    margin-top: 0.5em;
    background-color: $lightGreen;
    background-image: url(../images/common/chevron.png);
    background-position: center;
    background-repeat: no-repeat;
}

.container-fluid.p0 {
    padding-left: 0;
    padding-right: 0;
}

.green-gradient {
    background: $greenGradient;
}

.white {
    color: white;
}

.bg-true-white {
    background-color: white;
}

.gray-bg {
    background-color: #f7f7f7;
}

.green-white-circle {
    .content {
        img {
            border-radius: 100%;
            width: 100%;
            max-width: 55px;
        }

        transform: rotate(-45deg);
    }

    padding: 2px;
    border: 2px solid $lightGreen;
    border-top: 2px solid white;
    transform: rotate(45deg);
    border-radius: 50%;
}

.big-title {
    padding-left: 2em;
    border-left: 10px solid $lightGreen;
    margin-bottom: 2em;
    font-size: 1.75em;
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
    padding-top: 1em;
    padding-bottom: 1em;
}

.behind-logo {
    img {
        width: 100%;
    }
}

[data-lightbox="g2"] img {
    max-height: 100px;
    width: auto;
}

.open-sans {
    font-family: "Open Sans", sans-serif;
}

.grey-bg {
    background-color: #f2f2f2;
}

.left-side-video {
    width: 100%;
    min-height: 80vh;
    position: absolute;
    left: 0;
}

.gallery {
    .item {
        >div {
            padding: 5px;
        }

        // max-width: 80px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
        background-color: white;
        text-align: center;
        font-size: 0.9em;
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.move-to-top {
    position: relative;
    // margin-top: -60px;
}

.gray-border-right {
    border-right: 1px solid #dcdddd;
}

a.specification {
    &::after {
        content: " ";
        width: 2.2em;
        height: 100%;
        position: absolute;
        top: 0;
        right: -2.3em;
        display: inline-flex;
        background-color: #5ab331;
        background-image: url(../images/common/chevron.png);
        background-position: center;
        background-size: 30%;
        background-repeat: no-repeat;
    }

    font-size: 0.85em;
    font-weight: $medium;
    color: #2a2323;
    padding: 8px 15px;
    background-color: white;
    position: relative;
}

.no-bold {
    font-weight: normal;
}

.back-to-top {
    position: absolute;
    bottom: 0;
    left: calc(50% - 48px);
    width: 95px;
    cursor: pointer;
}

.gray-border-right {
    border-right: 1px solid #c8c9c9;
}

.gray-border-top {
    border-top: 1px solid #c8c9c9;
}

.site-logo a {
    display: flex;
    align-items: center;
}

#modalDialog {
    .actual-content {
        height: 100%;
    }

    .content {
        .thumbnails {
            .thumbs-wrap {
                img {
                    max-height: 100%;
                }

                height: 200px;
                width: 200px;
                margin: 1em;
                border: 1px solid #dcdddd;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }

            flex: 1;
            padding: 1.5em;
            flex-wrap: wrap;
            overflow-y: auto;
            max-height: 100%;
            height: auto;
        }

        .main-image-wrap {
            img {
                max-height: 70vh;
            }

            max-height: 90%;
            flex: 1;
        }

        width: 80%;
        height: 80%;
        background-color: white;
        margin-left: 10%;
        margin-top: 5%;
    }

    .close-button {
        position: fixed;
        top: 30px;
        right: 30px;
        color: white;
        font-size: 2.5em;
        font-weight: bold;
    }

    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0, 0, 0, 0.83);
    z-index: 500;
    display: none;
}

.menu-indicator {
    display: none;
}

.brodnet-logo {
    height: 1.3em;
}

body:not(.page-template-main) {
    header {
        .nav-container {
            .dark-nav {
                .search-row {
                    display: none;
                }
            }

            padding-bottom: 0;
            background: transparent;
        }
    }
}

#search-form {
    button[type="submit"] {
        background-image: url(../images/common/search-icon.webp);
        background-color: #5c9b3b;
        border: 1px solid #5c9b3b;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 0;
        height: 3em;
        width: 3em;
        transform: scaleX(-1);
    }

    #search {
        @include placeholderColor(white);
        border: 1px solid #5c9b3b;
        border-radius: 0;
        background-color: transparent;
        color: white;
        // font-family: $montserrat;
        font-weight: $medium;
        // font-size: 1.5em;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    display: flex;
    margin-top: 1.5em;
    margin-right: 1.5em;
}

#ajaxsearchlite1 {
    .orig {
        @include placeholderColor($lightGreen);
    }
}

body.search {
    .entry-title a {
        font-size: 1.5rem;
    }

    .post-thumbnail {
        img {
            height: 250px;
            object-fit: cover;
        }
    }

    .post,
    .page {
        margin: 0.7em;
    }
}

.scroll-wrap {
    table {
        td {
            white-space: nowrap;
            padding: 0px 0.5em;
        }
    }

    position: relative;
    overflow-x: auto;
}

.row.grey-top {
    >div {
        >.content {
            border-top: 1px solid #e2e2e2;
            padding-top: 2em;
        }
    }
}

.nav-button {
    cursor: pointer;
}

.privacy-policy ol {
    margin-left: 0;
}

html:not(.webp) {
    #search-form {
        button[type="submit"] {
            background-image: url(../images/common/search-icon.png);
        }
    }
}

.desc-on-bottom {
    white-space: normal;

    @media screen and (min-width: 768px) {
        white-space: nowrap;
    }
}

.new-btn {
    display: block;
    width: fit-content;
    padding: rem(16) rem(30);
    color: white;
    border: 2px solid #418335;
    border-radius: 5px;

    &:hover,
    &:visited {
        color: white;
    }

    &-filled {
        border: 0;
        background: #1b6b02;
        font-weight: 500;
        padding: rem(14) rem(28);
    }

    &.white {
        color: white;
        border-color: white;
    }
}

.green-decoration {
    position: relative;

    &::before {
        content: " ";
        display: block;
        height: 100%;
        width: 4px;
        border-radius: 2px;
        background: $frontPageGreenDecoration;
        position: absolute;
        left: rem(-24);
        top: 0;
    }

    &.thick::before {
        width: 9px;
        border-radius: 4px;
    }
}

img.fit-parent {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.prod-colors {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;

    @media screen and (min-width:576px) {
        padding-bottom: 3rem;
    }

    .colors {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 1rem;
        row-gap: 1rem;

        @media screen and (min-width: 1600px) {
            grid-template-columns: repeat(5, 1fr);
        }

        .actual-color {
            width: 4rem;
            height: 4rem;
            border-radius: 2px;

            @media screen and (min-width: 1200px) {
                width: rem(48);
                height: rem(48);
            }

            @media screen and (min-width: 1200px) {
                width: 4rem;
                height: 4rem;
            }

            img {
                border-radius: 2px;
            }
        }
    }
}

#cookie-notice {
    width: calc(100vw - 3rem);
    min-width: unset;
    right: 1.5rem;
    left: unset;
    bottom: 1.5rem;

    @media screen and (min-width: 768px) {
        width: 50vw;
    }

    @media screen and (min-width: 1200px) {
        width: 25vw;
    }

    @media screen and (min-width: 1400px) {
        width: 20vw;
    }

    .cn-close-icon {

        &::before,
        &::after {
            background: white;
        }
    }

    .cn-buttons-container {
        margin-top: 2rem;

        @media screen and (min-width: 1200px) {
            margin-top: 0.5rem;
        }

        a {
            color: #ffffff;
            background: #302f2f;
            padding: 0.5rem 0.75rem;
            font-weight: 700;
        }
    }
}

body.search {
    header {
        max-height: unset;
    }

    #main {
        #search-form #search {
            color: #2a2323;

            @media screen and (max-width: 767.98px) {
                width: 100%;
            }
        }
    }
}

@import "partials/mobile-product-nav";

@import "header";

@import "page-templates/stropy";
@import "page-templates/oferta";
@import "page-templates/home";
@import "page-templates/krawezniki";
@import "page-templates/kontakt";
@import "page-templates/produkt-level-1";
@import "page-templates/produkt-level-2";
@import "page-templates/ogrodzenia";
@import "page-templates/about-us";
@import "page-templates/downloads";
@import "page-templates/poradnik";
@import "page-templates/preparaty";
@import "page-templates/page-template-default";
@import "footer";

@import "colors-wrap";
@import "rwd";