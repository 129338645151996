@mixin aColor($color) {
	a,
	a:hover,
	a:visited {
		color: $color;
		text-decoration: none;
	}
}

@mixin placeholderColor($val) {
	&::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $val;
	}
	&::-moz-placeholder {
		/* Firefox 19+ */
		color: $val;
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		color: pink;
	}
	&:-moz-placeholder {
		/* Firefox 18- */
		color: $val;
	}
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
	// For Google Chrome
	&::-webkit-scrollbar {
		width: $size;
		height: $size;
	}

	&::-webkit-scrollbar-thumb {
		background: $foreground-color;
	}

	&::-webkit-scrollbar-track {
		background: $background-color;
	}

	// For Internet Explorer
	body {
		scrollbar-face-color: $foreground-color;
		scrollbar-track-color: $background-color;
	}
}


%fullSize {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

%flexCenterBothColumn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

%flexCenterVertically {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

%h3 {
	font-size: 2em;
	font-family: $montserrat;
	font-weight: bold;
	text-transform: uppercase;
}

%secondFooter {
	@media screen and (min-width: 768px) {
		header {
			.dark-nav .header-contact {
				display: none;
			}
		}
		.dark-nav {
			.header-contact {
				.item {
					margin: 5px !important;
				}
				justify-content: space-around !important;
			}
		}
	}
	@media screen and (max-width: 767.98px) {
		footer {
			display: none;
		}
	}
	footer {
		.row {
			display: none;
			padding: 0;
		}
		.menu-row {
			display: flex;
			margin: 0;
		}
		.row:not(.green) {
			margin: 0;
		}
		background-color: #252728;
		// position: fixed;
		width: 100%;
		// bottom: 0;
	}
}

%ogrody-green-title {
	padding-left: 2em;
	border-left: 2px solid $lightGreen;
	padding-top: 5px;
	padding-bottom: 5px;
	font-weight: bold;
}

@mixin mobilePadding {
	padding-top: 6em;
	padding-bottom: 6em;
}
