.page-template-krawezniki {
	.krawezniki {
		div {
			img {
				margin-bottom: 1em;
				margin-top: 1em;
			}
			p {
				margin-bottom: 0;
			}
			// flex: 1;
			padding: 0.7em;
			white-space: nowrap;
			// border: 1px solid #dcdddd;
		}
		// border-bottom: 1px solid #dcdddd;
	}
	.krawezniki-bg-2 {
		flex: 2;
		background-image: url("../../images/krawezniki/bg-2.webp");
		@extend %fullSize;
	}
	.krawezniki-bg-3 {
		flex: 2;
		background-image: url("../../images/krawezniki/bg-3.webp");
		@extend %fullSize;
	}
	.pad {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.k-row {
		.items {
			.item {
				img {
					margin-top: 1.5em;
					margin-bottom: 1em;
				}
				p {
					margin-bottom: 0;
				}
				font-weight: $medium;
				font-family: "Open Sans", sans-serif;
				font-size: 0.95em;
				// flex: 1;
				padding: 0.7em;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: start;
				// border: 1px solid #d3d4d4;
				white-space: nowrap;
				margin: 0 auto;
			}
			justify-content: flex-start;
		}
	}
}

.page-id-869 {
	.krawezniki, .items {
		.item {
			margin: 0 !important;
		}
		justify-content: flex-start !important;
	}
}

.page-template-krawezniki, 
.page-id-878,
.page-id-846 {
	table {
		tr:first-child {
			font-weight: bolder;
		}
		tr:not(:last-child) {
			border-bottom: 1px solid gray;
		}
		tr {
			td:not(:first-child) {
				border-left: 1px solid gray;
			}
		}
		td {
			text-align: center;
			padding: .3em;
		}
	} 
}

.page-id-846 {
	.krawezniki {
		img {
			margin-top: 1em;
			margin-bottom: 1em;
		}
	}
}

html:not(.webp) {
	.page-template-krawezniki {
		.krawezniki-bg-2 {
			background-image: url("../../images/krawezniki/bg-2.jpg");
		}
		.krawezniki-bg-3 {
			background-image: url("../../images/krawezniki/bg-3.jpg");
		}
	}
}