.page-template-stropy {
	#content {
		.d-flex {
			.item:last-child {
				margin-left: 7em;
			}
		}
	}
	table {
		thead {
			tr {
				th:first-child {
					background: transparent;
				}
				th {
					background-color: #fcfcfc;
					font-weight: normal;
					text-align: center;
					padding: 10px;
				}
			}
		}
		tbody {
			tr {
				td:first-child {
					background-color: #a8a8a8;
					color: white;
				}
				td {
					background-color: #fcfcfc;
					padding: 5px 20px;
					text-align: center;
					padding: 10px;
				}
			}
		}
		border-spacing: 3px;
		border-collapse: separate;
	}
	.grey-td {
		background-color: $lightGrey;
	}
	.rows-wrap {
		> .row:nth-child(odd) {
			background-color: #ffffff;
		}
		> .row:nth-child(even) {
			background-color: #f5f5f5;
		}
		> .row {
			padding-bottom: 2.8em;
			padding-top: 2.8em;
		}
	}
	.behind-logo {
		background-color: #e8e8e8;
	}
}