footer {
    background-color: #fff;
    position: relative;
    .row {
        &:not(.green) {
            margin-bottom: 110px;
            margin-top: 140px;
            h4 {
                font-weight: 700;
            }
            .item {
                &:not(:last-child) {
                    margin-right: 70px;
                }
            }
            .green-hr {
                margin-left: 0;
            }
        }
    }

    .eu-funding {
        img {
            @media screen and (min-height:992px) {
                width: 40vw;                
            }
        }
    }

    .row.green {
        background-color: #232323;
        color: #fff;
        font-family: Montserrat, sans-serif;
        font-size: 0.9em;
        font-weight: 500;
        padding-bottom: 30px;
        padding-top: 30px;
        p {
            margin-bottom: 0;
        }
        > div {
            &:first-child {
                a {
                    color: #fff;
                    text-decoration: underline;
                    &:hover {
                        color: #fff;
                        text-decoration: underline;
                    }
                    &:visited {
                        color: #fff;
                        text-decoration: underline;
                    }
                }
            }
        }
        a {
            color: #fff;
            text-decoration: none;
            &:hover {
                color: #fff;
                text-decoration: none;
            }
            &:visited {
                color: #fff;
                text-decoration: none;
            }
        }
    }
    .menu-footer-menu-container {
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li {
                border-bottom: 1px dashed #dbdbdc;
                margin: 0;
                padding: 10px 2px;
                &:first-child {
                    padding-top: 0;
                }
                a {
                    color: #111626;
                    text-decoration: none;
                    &:hover {
                        color: #111626;
                        text-decoration: none;
                    }
                    &:visited {
                        color: #111626;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .menu-row {
        display: none;
    }
    .youtube {
        display: none !important;
    }
    .dark-nav {
        display: none;
    }
}
