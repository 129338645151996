header {
    font-family: $frontPageFont;
    max-height: var(--headerMaxHeight);
    transition: transform 0.3s ease-in-out;

    &.slide-up {
        transform: translateY(calc(-1 * 1.75 * var(--headerMaxHeight)));
    }

    .nav-container {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.24), transparent);
        padding: 0;

        .nav-logo {
            background: white;
            z-index: 35;
            max-height: var(--headerMaxHeight);
            display: grid;
            grid-template-columns: 1.5fr 1fr;

            column-gap: 2rem;

            @media screen and (min-width: 768px) {
                display: flex;
                column-gap: 0;
                padding: 0.5rem;
            }

            @media screen and (min-width: 1200px) {
                padding: 0;
            }

            img {
                max-height: rem(91);
                height: 100%;
            }
        }

        .site-logo {
            
            height: 150%;
            padding: rem(0) rem(5) rem(0) rem(5);
            z-index: 1;
            
            @media screen and (min-width: 768px) {
                height: 110%;
            }
            
            @media screen and (min-width: 1200px) {
                background: white;
                padding: rem(5) rem(30);
                height: 140%;
                border-bottom-right-radius: 40px;
                box-shadow: 0px 2px 20px 0px black;
            }

            @media screen and (min-width: 1850px) {
                padding: rem(15) rem(30);
            }
        }

        .eu-logo {
            background: linear-gradient(90deg, #f3f3f3, white 20%, white);
            justify-content: space-evenly;
            gap: 1rem;

            @media screen and (min-width: 1200px) {
                padding: rem(30);
            }

            img {
                max-height: rem(36);
                height: 100%;
                object-fit: contain;
            }

            a:nth-child(2) {
                img {
                    max-height: 3.5rem !important;
                }
            }
        }
    }

    #nav-toggle {
        margin-right: 1em;
        background: url(../images/common/bars.svg);
        background-repeat: no-repeat;
        background-size: contain;
        border: 0;
        height: 25px;
        width: 25px;
    }

    #nav-close {
        background: url(../images/common/bars.svg);
        background-repeat: no-repeat;
        background-size: contain;
        border: 0;
        height: 25px;
        width: 25px;
        background-image: url(../images/common//close.svg);
        justify-self: flex-end;
        margin: 2em;
        z-index: 20;
    }

    .search-toggle {
        background: url(../images/new_homepage/new-search-icon.webp);
        background-repeat: no-repeat;
        background-size: contain;
        border: 0;
        height: rem(47);
        width: rem(47);
        background-image: url(../images/new_homepage/new-search-icon.webp);
        justify-self: flex-end;
        margin-left: 0.5em;
    }

    #offerSubmenu {
        display: none;
        margin: 0 auto;
        opacity: 0;
        position: fixed;
        right: 0;
        top: var(--headerMaxHeight);
        transition: opacity 0.3s;

        @media screen and (min-width: 1200px) {
            width: 100vw;
        }

        ul {
            display: block;
            padding-left: 0;

            li {
                // border-left: 3px solid #5ab331;
                border-top: none;
                display: list-item;
                line-height: 1;
                list-style: none;
                margin: 1em 0;

                a {
                    padding: 0;
                    font-size: 1rem;

                    @media screen and (min-width: 1850px) {
                        font-size: rem(18);
                    }
                }
            }
        }

        .container {
            background: #1f1f1f;
        }

        #menu-oferta-poziom-1 {
            font-weight: 700;
        }

        #menu-oferta-poziom-2 {
            font-weight: 700;
        }

        .row {
            @media screen and (min-width: 1200px) {
                box-shadow: 0 7px 15px 9px rgba(0, 0, 0, 0.34);
            }
        }
    }

    .dark-nav {
        display: block;
    }

    .row-submenu-grid {
        padding: 0 1rem;

        @media screen and (min-width: 992px) {
            padding: 1.5rem;
        }

        @media screen and (min-width: 1600px) {
            padding: rem(70);
        }

        .submenu-grid {
            display: grid;
            grid-template-columns: 1fr;

            @media screen and (min-width: 1200px) {
                grid-template-columns: 2fr 2fr 1fr;
            }

            .termoblock {
                grid-row: span 3;
                display: none;

                @media screen and (min-width: 1200px) {
                    display: block;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }

            .separator {
                display: none;
                height: 1px;
                background-color: #484848;
                margin-top: rem(23);
                margin-bottom: rem(23);

                @media screen and (min-width: 1200px) {
                    grid-column: span 2;
                    display: block;
                }
            }

            .grid-menu-row {
                display: grid;
                grid-template-columns: 1fr;
                justify-content: space-between;
                column-gap: 1rem;

                @media screen and (min-width: 1200px) {
                    padding-right: 2rem;
                    grid-template-columns: 2fr 1fr;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.header {
    color: #fff;
    display: flex;
    flex-direction: column;
    padding-bottom: 4vh;
    padding-top: 8vh;
    text-align: center;

    .mouse-icon {
        margin-top: 6vh;
    }

    .smaller {
        font-family: Montserrat, sans-serif;
        font-weight: 700;
    }

    h1 {
        font-family: Montserrat, sans-serif;
        font-weight: 700;
        font-size: 5em;
        line-height: 1;
    }
}

#menu-item-406 {
    margin-right: rem(32);
    position: relative;

    @media screen and (min-width: 1200px) {
        &::after {
            content: "";
            width: 2px;
            height: 100%;
            background: #99be7a;
            position: absolute;
            display: block;
            right: rem(-16);
            height: rem(18);
        }
    }
}

.dark-nav {
    .menu-row {
        background: $menuGreen;
        font-family: $frontPageFont;
        font-size: 1em;
        position: relative;
        z-index: 40;

        >div {
            &:first-child {
                font-weight: 500;

                a {
                    color: #fff;
                    text-decoration: none;
                    font-size: rem(16);

                    font-size: 0.9rem;
                    height: var(--headerMaxHeight);

                    @media screen and (min-width: 1850px) {
                        font-size: rem(18);
                    }

                    &:hover {
                        color: #fff;
                        text-decoration: none;
                    }

                    &:visited {
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }

        img.icon {
            margin-right: 15px;
        }

        .header-contact {
            flex-wrap: wrap;
            height: 100%;

            img  {
                @media screen and (max-width:3000px) {
                    width: calc(1.8rem + .4vw);
                }

                width: 2.2rem;
            }
        }
    }

    .search-row {
        display: flex;
        justify-content: flex-end;
    }

    .menu-item {
        text-transform: none;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0;
        // padding: 0 rem(24) 0 rem(17);
        font-size: rem(18);

        li {
            display: inline-flex;
            align-items: center;

            >a {
                padding: rem(24) rem(6) rem(24) rem(6);
                white-space: nowrap;

                @media screen and (min-width: 1850px) {
                    padding: rem(31.5) rem(15) rem(31.5) rem(31);
                }
            }

            a {
                color: #fff;
                text-decoration: none;
                font-weight: 500;

                &:hover {
                    color: #fff;
                    text-decoration: none;
                }

                &:visited {
                    color: #fff;
                    text-decoration: none;
                }
            }
        }

        .mobile-top {
            display: flex;

            img {
                max-height: 60px;
                max-width: 300px;
                width: 100%;
            }

            a {
                margin: 1em;
            }

            picture {
                margin: 0;
            }
        }
    }

    .main-menu-wrap {
        .header-contact {
            background: $menuDarkGreen;
            padding: 0 0.9rem;

            @media screen and (min-width: 1600px) {
                padding: 0 rem(47);
            }

            .item {
                margin-right: rem(17);

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

#menu-header {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @media screen and (min-width: 1600px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

li.search-toggle {
    align-self: center;
    border-top: none;
    cursor: pointer;
    position: relative;
    list-style-type: none;
    margin-right: 1rem;
    width: calc(1.8rem + .4vw);
    height: 2rem;

    @media screen and (min-width: 1200px) {
        margin-right: 0;
        margin-left: 1em;
    }

    #float-search {
        position: absolute;
        top: 100%;
        right: rem(-20);
        display: none;

        &.open {
            display: block;
        }

        .separator {
            height: 1.5rem;
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                right: rem(20);
                top: 1px;
                width: 1.5rem;
                height: 1.5rem;
                border-top: 0rem solid yellow;
                border-right: 1.5rem solid transparent;
                border-bottom: 1.5rem solid #1f1f1f;
                border-left: 1.5rem solid transparent;
            }
        }

        .form-wrap {
            padding: 1rem;
            background: #1f1f1f;
            border-radius: 0.25rem;

            form {
                margin: 0;

                input {
                    width: 200px;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .dark-nav {
        .menu-row {
            a {
                font-size: 0.8rem;
            }
        }
    }
}

#masthead {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1900;
    max-height: var(--headerMaxHeight);

    >div {
        >.no-gutters {
            max-height: var(--headerMaxHeight);

            .dark-nav {
                max-height: var(--headerMaxHeight);
            }
        }
    }
}