.page-template-produkt-level-2 {
    @mixin common-padding {
        padding: 1rem;

        @media screen and (min-width: 1600px) {
            padding: 1.75rem;
        }
    }

    @mixin controls {

        .up,
        .down {
            // padding: 1rem;
            text-align: center;
            cursor: pointer;
            -webkit-user-select: none;
            user-select: none;

            display: none;

            @media screen and (min-width: 1200px) {
                display: block;
            }
        }

        .up {
            img {
                transform: rotate(-90deg);
            }
        }

        .down {
            img {
                transform: rotate(90deg);
            }
        }
    }

    #content {
        @media screen and (min-width: 1200px) {
            font-size: 0.9rem;
        }

        @media screen and (min-width: 1600px) {
            font-size: 1rem;
        }
    }

    h5 {
        font-size: 0.7rem;
        border: 2px solid white;
        background: #41810c;
        padding: 1rem;
        color: white;
        max-width: 264px;
        text-transform: uppercase;
    }

    .lvl2-wrap {
        display: grid;
        grid-template-columns: 1fr;
        overflow-y: hidden;
        height: unset;

        @media screen and (min-width: 768px) {
            grid-template-rows: 28vh 25vh 50vh;
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (min-width: 1600px) {
            grid-template-rows: 32vh 25vh 50vh;
        }

        @media screen and (min-width: 1200px) {
            grid-template-rows: 35vh 26vh 50vh;
        }

        @media screen and (min-width: 1200px) {
            max-height: calc(100vh - var(--headerMaxHeight));
            grid-template-rows: 4fr 1.75fr;
            grid-template-columns: 0.8fr 1.1fr 1fr 1.5fr;
            height: calc(100vh - 67px);
        }

        @media screen and (min-width: 1600px) {
            grid-template-columns: 0.875fr 1fr 1fr 1.75fr;
            grid-template-rows: 4fr 1.5fr;
            height: calc(100vh - 90px);
        }

        &.no-techs {
            .variants {
                @media screen and (min-width: 1200px) {
                    grid-row: span 2;
                }
            }
        }

        &.no-colors {
            .description {
                @media screen and (min-width: 1200px) {
                    grid-row: span 2;
                }
            }
        }

        &.no-variants {
            .description {
                @media screen and (min-width: 1200px) {
                    grid-column: span 2;
                }
            }
        }

        &.no-techs.no-colors {
            @media screen and (min-width: 768px) and (max-width: 1199.98px) {
                .description {
                    grid-column: span 2;
                }
            }

            @media screen and (max-width: 767.98px) {
                .list-wrap {
                    grid-row: 6;
                }
            }
        }

        >div {
            @media screen and (min-width: 1200px) {
                max-height: calc(100vh - var(--headerMaxHeight));
            }
        }

        h1 {
            font-size: rem(32);

            @media screen and (min-width: 1200px) {
                font-size: rem(24);
                font-weight: 700;
            }

            @media screen and (min-width: 1600px) {
                font-size: rem(38);
            }
        }

        h2 {
            font-size: rem(20);

            @media screen and (min-width: 1600px) {
                font-size: rem(26);
            }
        }

        h1,
        h2 {
            position: relative;
            padding-bottom: rem(32);

            @media screen and (min-width: 1200px) {
                padding-bottom: 1.5rem;
            }

            @media screen and (min-width: 1600px) {
                padding-bottom: rem(45);
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                background: #009c3c;
                left: 0;
                width: rem(34);
                height: 4px;
                border-radius: 2px;
                bottom: rem(18);
                top: unset;

                @media screen and (min-width: 1600px) {
                    bottom: rem(25);
                }
            }
        }

        .breadcrumbs {
            display: flex;
            font-size: rem(11);
            color: #626262;
            margin-bottom: 1rem;

            @media screen and (min-width: 1600px) {
                font-size: rem(13);
            }

            .separator {
                margin: 0 0.25rem;

                @media screen and (min-width: 1600px) {
                    margin: 0 0.5rem;
                }
            }

            a {
                color: currentColor;
            }
        }

        .list-wrap {
            background: #2f2f2f;
            grid-column: span 2;
            display: flex;
            flex-direction: column;

            @media screen and (min-width: 1200px) {
                grid-row: span 2;
                grid-column: unset;
            }

            @include controls;

            .list {
                font-size: rem(16);
                color: white;
                overflow-y: auto;
                @include scrollbars(0.75rem, #5c9b3b, transparent);

                @media screen and (min-width: 1600px) {
                    font-size: rem(18);
                }

                .item {
                    border-bottom: 1px solid #405440;
                    padding: rem(18);
                    cursor: pointer;
                    display: block;
                    color: currentColor;

                    @media screen and (min-width: 1200px) {
                        padding: 1rem;
                    }

                    @media screen and (min-width: 1600px) {
                        padding: rem(24);
                    }

                    &.selected {
                        background: #69ac47;
                        font-weight: 600;
                    }
                }
            }

            .up,
            .down {
                transition: opacity 0.3s;
            }
        }

        .description {
            background: #f2f2f2;
            @include common-padding;
            grid-row: 1;
            grid-column: 1;

            @media screen and (min-width: 768px) {
                grid-row: 1 / span 2;
                grid-column: 1;
            }

            @media screen and (min-width: 1200px) {
                grid-row: unset;
                grid-column: unset;
            }
        }

        .variants {
            background: white;
            position: relative;
            @include common-padding;
            @include controls;
            @include scrollbars(0.75rem, #5c9b3b, transparent);
            grid-row: 5;
            grid-column: 1;

            &.one-col {
                .grid-wrap {
                    grid-template-columns: 1fr;
                }
            }

            @media screen and (min-width: 768px) {
                &:not(.pad, .c-pad-t, .c-pad-b) {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }

                &.c-pad-t {
                    @media screen and (min-width: 1200px) {
                        padding-top: 1.75rem;
                    }
                }

                &.c-pad-b {
                    @media screen and (min-width: 1200px) {
                        padding-bottom: 1.75rem;
                    }
                }

                grid-row: 3;
                grid-column: 1;
            }

            @media screen and (min-width: 1200px) {
                grid-row: unset;
                grid-column: unset;
            }

            .up,
            .down {
                position: sticky;
                // display: none;
                transition: transform 0.3s ease-in-out;

                @media screen and (min-width: 768px) {
                    justify-content: center;
                }

                &.hide {
                    display: none;
                }

                img {
                    border: 10px solid green;
                    background: green;
                    border-radius: 20px;
                    width: 30px;
                    height: 40px;
                }
            }

            .up {
                top: 0;
                transform: translateY(-80px);
                padding-top: 1rem;

                &.show {
                    transform: translateY(0);
                }
            }

            .down {
                bottom: 0;
                transform: translateY(80px);
                padding-bottom: 1rem;

                &.show {
                    transform: translateY(0px);
                }

                @media screen and (min-width: 768px) {
                    left: unset;
                }
            }

            .grid-wrap {
                display: grid;
                //comes from PHP
                grid-template-columns: repeat(var(--variants-cols), 1fr);
                column-gap: 0.5rem;

                .dimensions {
                    p {
                        margin-bottom: 0;
                    }
                }
            }

            overflow: auto;
            // height: fit-content;

            .variant {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                text-align: center;
                font-size: rem(14);
                margin-bottom: 1rem;

                .image {
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                }
            }
        }

        .photos {
            grid-row: 2;
            grid-column: 1;
            position: relative;

            @media screen and (min-width: 768px) {
                grid-row: 3;
                grid-column: 2;
            }

            @media screen and (min-width: 1200px) {
                grid-row: span 2;
                grid-column: unset;
            }

            .controls {
                position: absolute;
                bottom: 0;
                right: 0;
                background: #212121;
                display: flex;

                >div {
                    padding: 1rem;
                    cursor: pointer;

                    img {
                        width: 1rem;
                    }

                    &.next {
                        img {
                            transform: scaleX(-1);
                        }
                    }
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .tns-outer {
                height: 100%;

                .tns-inner {
                    height: 100%;

                    .photos-wrap {
                        height: 100%;

                        >.photo-wrap {
                            height: 100%;

                            .img-wrap {
                                height: 100%;
                                position: relative;

                                .label {
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    color: #f2f2f2;
                                    background: #212121;
                                    padding: 0.8rem 2rem;
                                    font-weight: bold;
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
            }
        }

        >.colors {
            background: #212121;
            color: white;
            @include common-padding;
            grid-row: 3;
            grid-column: 1;

            @media screen and (min-width: 768px) {
                grid-row: 1;
                grid-column: 2;
            }

            @media screen and (min-width: 1200px) {
                grid-row: unset;
                grid-column: unset;
            }

            .colors {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 1rem;

                @media screen and (min-width: 576px) {
                    padding-left: 2rem;
                    padding-right: 1rem;
                    gap: 2rem;
                }

                @media screen and (min-width: 768px) {
                    padding-left: 0rem;
                    padding-right: 0rem;
                    gap: 2rem;
                }

                @media screen and (min-width: 992px) {
                    padding-left: 3rem;
                    padding-right: 3rem; 
                }

                @media screen and (min-width: 992px) {
                    grid-template-columns: repeat(3, 1fr);
                }

                @media screen and (min-width: 1800px) {
                    grid-template-columns: repeat(4, 1fr);
                }

                .actual-color {
                    height: 4.2rem;
                    width: 4.2rem;
                    border-radius: 4px;
                    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                    box-shadow: 0px 0px 0px transparent;

                    &:hover {
                        box-shadow: 0px 0px 0.5rem var(--cc);
                        transform: scale(1.1);
                    }

                    @media screen and (min-width: 576px) {
                        height: 6rem;
                        width: 6rem;
                    }

                    @media screen and (min-width: 768px) {
                        height: 4rem;
                        width: 4rem;
                    }

                    @media screen and (min-width: 992px) {
                        max-height: 5rem;
                        max-width: 5rem;
                    }

                    @media screen and (min-width: 1200px) {
                        max-height: 5rem;
                        max-width: 5rem;
                    }
                    
                    @media screen and (min-width: 1600px) {
                        max-height: unset;
                        max-width: unset;
                    }

                    img {
                        border-radius: 2px;
                    }
                }
            }
        }

        .green-title+.grey-top {
            p {
                @media screen and (min-width: 1200px) {
                    margin-bottom: 0;
                }

                @media screen and (min-width: 1600px) {
                    margin-bottom: 1rem;
                }
            }

            // img {
            //     height: 15vh;
            //     width: auto;

            //     @media screen and (min-width: 1200px) {
            //         display: none;
            //     }

            //     @media screen and (min-width: 1600px) {
            //         display: block;
            //     }
            // }
        }

        .techs {
            background: #2f2f2f;
            color: white;
            @include common-padding;

            grid-row: 4;
            grid-column: 1;

            @media screen and (min-width: 768px) {
                grid-row: 2;
                grid-column: 2;
            }

            @media screen and (min-width: 1200px) {
                grid-row: unset;
                grid-column: unset;
            }

            .tech-boxes {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 1rem;

                @media screen and (min-width: 768px) {
                    gap: 0.9rem;
                }

                @media screen and (min-width: 1200px) {
                    gap: 1rem;
                }

                .box {
                    img {
                        filter: invert(1);

                        @media screen and (min-width: 768px) and (max-width: 1199.98px) {
                            max-width: rem(60);
                        }
                    }
                }
            }
        }
    }

    footer {
        display: none;
    }

    header .nav-container .site-logo {
        height: 100%;
        border-bottom-right-radius: 0;
    }
}