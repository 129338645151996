.page-template-default {
    h1 {
        font-size: 2rem;
        padding-top: 2rem;
    }

    .entry-header {
        max-height: unset;
        text-align: center;
    }
}