
.page-template-ogordzenia {
    a,
	a:visited,
	a:hover {
		&.green-button-chevron {
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-end;
		}
    }
	.move-to-top > div {
		.images {
			background-color: white;
			margin-top: 1em;
			margin-bottom: 1em;
		}
		.more {
			text-align: right;
		}
		padding-top: 100px;
		padding-bottom: 60px;
	}
	.green-title {
		@extend %ogrody-green-title;
		text-transform: uppercase;
		font-size: 1.875em;
	}
	#content .content {
		max-width: 700px;
		margin-left: 3.5em;
		margin-right: 3.5em;
	}

	.fences {
		.title {
			@extend %ogrody-green-title;
			text-transform: uppercase;
			margin-bottom: 2em;
		}
		.fence-content {
			* {
				flex: 1;
			}
			table.ogrodzenia-table {
				th,
				td {
					padding: 5px;
					vertical-align: middle;
				}
				font-size: 0.9em;
				width: auto;
			}
			picture {
				text-align: center;
			}
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
		}
		.fence {
			max-width: 1366px;
			width: 100%;
			margin-left: 5em;
			margin-right: 5em;
			background: white;
			padding: 1.5em;
		}
	}
	.second-row {
		> div > .title {
			.green-title {
				padding-top: 0;
				padding-bottom: 0;
			}
			padding-bottom: 100px;
		}
		padding-top: 100px;
		padding-bottom: 230px;
	}
}
