$green: #4f9f36;
$lightGreen: #5ab331;
$darkGreen: #2c6c20;
$greenGradient: linear-gradient(to right, #5cac37, #449337);
$montserrat: "Montserrat", sans-serif;
$openSans: "Open Sans", sans-serif;
$menuBorderHeight: 5px;
$semi-bold: 600;
$medium: 500;
$dark: #111626;
$lightGrey: #e5e5e5;
$menuGreen: #1b6b02;
$menuDarkGreen: #083500;
//new front page
$frontPageFont: "Poppins", sans-serif;
$frontPageLightGrey: #302f2f;
$frontPageGreenDecoration: #009c34;

$ml: 14vw;
$mr: 14vw;
$mb: 6vh;
$mt: 6vh;

@font-face {
    font-family: "Museo";
    src: url("../fonts/Museo\ 500.otf");
    font-display: swap;
}
@font-face {
    font-family: "Museo";
    src: url("../fonts/Museo\ 300.otf");
    font-weight: lighter;
    font-display: swap;
}
@font-face {
    font-family: "Museo";
    src: url("../fonts/Museo\ 700.otf");
    font-weight: bold;
    font-display: swap;
}

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1667px,
) !default;
